import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favico from '../static/favicon.png';

type SEOPropsType = {
  children?: ReactNode;
  location?: {
    href: string;
  };
  description?: string;
  title?: string;
  image?: string;
};

type Metadata = {
  siteMetadata: {
    title: string;
    description: string;
  };
};

export default function SEO({ children, location, description, title }: SEOPropsType) {
  const queryResult = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const site: Metadata = queryResult.site;

  return (
    <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`} defaultTitle={site.siteMetadata.title}>
      <html lang="fr" />
      <title>{title}</title>
      {/* Fav Icons */}
      <link rel="icon" type="image/png" href={favico} />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content="/logo.png" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:site_name" content={site.siteMetadata.title} key="ogsitename" />
      <meta property="og:description" content={description} key="ogdesc" />
      {children}
    </Helmet>
  );
}
